import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { StaticImage } from "gatsby-plugin-image";
import useLang from "Commons/Hooks/useLang";

import "./styles.scss";

const CompanyVision = () => {
  const [lang] = useLang();

  const dataMock = [
    [
      { title: "Our mission", description: "We offer cybersecurity solutions to our clients. We protect their information assets, optimize their processes and minimize their risks. Our services include consulting, analysis and implementation of security measures." },
      { title: "Our values", description: "We act with ethics, respect and responsibility. These values are the foundation of ArtsSEC. We value teamwork, quality and customer satisfaction. We are professional, honest and transparent." },
      { title: "Our vision", description: "We love innovation and creativity. Our goal is to provide tailor-made solutions that add value to our clients and help them overcome their challenges. We rely on our experience and knowledge to offer services that exceed expectations." },
    ],
    [
      { title: "Nuestra misión", description: "Ofrecemos soluciones de seguridad informática a nuestros clientes. Protegemos sus activos de información, optimizamos sus procesos y minimizamos sus riesgos. Nuestros servicios incluyen consultoría, análisis e implementación de medidas de seguridad." },
      { title: "Nuestros valores", description: "Actuamos con ética, respeto y responsabilidad. Estos valores son la base de ArtsSEC. Valoramos el trabajo en equipo, la calidad y la satisfacción del cliente. Somos profesionales, honestos y transparentes." },
      { title: "Nuestra visión", description: "Nos encanta la innovación y la creatividad. Nuestro objetivo es brindar soluciones a medida que agreguen valor a nuestros clientes y les ayuden a superar sus desafíos. Nos apoyamos en nuestra experiencia y conocimiento para ofrecer servicios que excedan las expectativas." },
    ],
  ];

  return (
    <article className="container-vision">
      {dataMock[lang].map((item, index) => (
        <section className="section-vision" key={item.title + index}>
          <span className="title-vision">{item.title}</span>
          <p className="description-vision">{item.description}</p>
        </section>
      ))}
    </article>
  );
};

export default CompanyVision;
