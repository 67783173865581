import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Col, Row } from "react-grid-system";
import Background from "Images/company/background.svg";
import Button from "Commons/Button";
// import CompanyCarousel from "Components/Company/CompanyCarousel";
import useLang from "Commons/Hooks/useLang";

import brochure from "../../../assets/ArtsSec_Brochure.pdf";
import brochureES from "../../../assets/ArtsSEC_Brochure_ES.pdf";

import "./styles.scss";
import CompanyVision from "../CompanyVision";

const CompanyHero = () => {
  const [lang] = useLang();

  const data = useStaticQuery(graphql`
    query allContentfulCompanyTextsQuery {
      allContentfulCompanyTexts {
        nodes {
          ourTeam
          pageTitle
          seeOpenings
          openingsTitle
          title
        }
      }
      allContentfulServicesTexts {
        nodes {
          downloadBrochure
        }
      }
    }
  `);

  const commonTexts = data.allContentfulCompanyTexts.nodes[lang];

  const { downloadBrochure } = data.allContentfulServicesTexts.nodes[lang];

  return (
    <div className="company-hero" id="aboutUs">
      <Background className="company-hero_background" />
      <div className="company-hero_text">
        <Container>
          <Row>
            <Col md={6} lg={5}>
              <span className="company-hero_title">{commonTexts.title}</span>
              <div className="company-hero_link">
                <a href="#careers">{commonTexts.seeOpenings}</a>
              </div>
              <a href={downloadBrochure === "Download Brochure" ? brochure : brochureES} download>
                <Button text={downloadBrochure} className={"company-hero_button"} />
              </a>
            </Col>
          </Row>
          <Row>
            <CompanyVision />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CompanyHero;
