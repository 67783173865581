import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Header from "Commons/Header";
import Footer from "Commons/Footer";
import CompanyHero from "Components/Company/CompanyHero";
// import CompanyTeam from "Components/Company/CompanyTeam";
// import CompanyOpenings from "Components/Company/CompanyOpenings";
import CompanySkills from "Components/Company/CompanySkills";
import CompanyPosts from "Components/Company/CompanyPosts";
import useLang from "Commons/Hooks/useLang";

const CompanyPage = ({ data }) => {
  const [lang] = useLang();
  const { pageTitle } = data.allContentfulCompanyTexts.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header />
      <CompanyHero />
      {/* <CompanyTeam /> */}
      {/* <CompanyOpenings /> */}
      <CompanySkills />
      <CompanyPosts />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getCompanyPageTexts {
    allContentfulCompanyTexts {
      nodes {
        pageTitle
      }
    }
  }
`;

export default CompanyPage;
