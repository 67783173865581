import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Col, Row } from "react-grid-system";
import { StaticImage } from "gatsby-plugin-image";
import useLang from "Commons/Hooks/useLang";

import "./styles.scss";

const CompanySkills = () => {
  const [lang] = useLang();
  const data = useStaticQuery(graphql`
    query getConferencesTitle {
      allContentfulCompanyTexts {
        nodes {
          conferencesTitle
        }
      }
    }
  `);

  const commonTexts = data.allContentfulCompanyTexts.nodes[lang];
  const iconsList = [
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/blackhat.png"}
    />,
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/8.png"}
    />,
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/h2hc.png"}
    />,
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/nerdearla.png"}
    />,
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/owasp.png"}
    />,
    <StaticImage
      imgStyle={{ height: "100%" }}
      style={{ width: "100%" }}
      objectFit="contain"
      src={"../../../images/company/ekoparty.png"}
    />,
  ];

  const mappedIcons = iconsList.map((icon, index) => {
    return (
      <Col xs={12} md={4} lg={4} key={index} className="company-skills_brand">
        {icon}
      </Col>
    );
  });

  return (
    <div className="company-skills">
      <Container>
        <Row justify="center">
          <Col xs={12} md={3} lg={3}>
            <div>
              <p className="company-skills_title">
                {commonTexts.conferencesTitle}
              </p>
            </div>
          </Col>
          <Row justify="end" className="company-skills_brands">
            {mappedIcons}
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default CompanySkills;
