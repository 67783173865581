import React from "react";
import { Container, Col, Row } from "react-grid-system";
import { graphql, useStaticQuery } from "gatsby";
import GreenArrow from "Images/company/green-arrow.svg";
import useLang from "Commons/Hooks/useLang";

import "./styles.scss";

const MediumPost = ({ post }) => (
  <Col xs={12} md={4} className="medium-post">
    <a href={`https://www.medium.com/@artssec/${post.uniqueSlug}`} className="medium-post__title">
      <h4>{post.title}</h4>
    </a>
    <a href={`https://www.medium.com/@artssec/${post.uniqueSlug}`}>
      <GreenArrow />
    </a>
    <p>{post.minread}</p>
  </Col>
);

const CompanyPosts = () => {
  const [lang] = useLang();
  const data = useStaticQuery(graphql`
    query getMediumPosts {
      allMediumPost(sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            title
            uniqueSlug
            createdAt
            virtuals {
              subtitle
              previewImage {
                imageId
              }
            }
            author {
              name
            }
          }
        }
      }
      allContentfulCompanyTexts {
        nodes {
          latestNews
        }
      }
    }
  `);
  const mediumPosts = data.allMediumPost.edges;
  const { latestNews } = data.allContentfulCompanyTexts.nodes[lang];

  return (
    <Container className="company-posts" id="resources">
      <h3>{latestNews}</h3>
      <Row>
        {mediumPosts.map(({ node }) => (
          <MediumPost post={node} />
        ))}
      </Row>
    </Container>
  );
};

export default CompanyPosts;
